import React, { useState, useEffect, Fragment } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import moment from "moment";
import QRCode from "react-qr-code";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Autocomplete, Checkbox, FormControlLabel } from "@mui/material";
import { BASE_URL, DOMAIN, SRF_ID_BASE } from "./../../global";
import { Grid, TextField, Toolbar } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ToastContainer, toast } from "react-toastify";
import Global from "../image/global.png";
import { useReactToPrint } from "react-to-print";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import ExcelDownload from "../../utils/components/excelDownload";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import { useSettings, useSrfDetails } from "../srf/editSrf/fetchHandlers";
import { handleCreateSubmit } from "../srf/editSrf/submitHandlers";
import { generateNumber } from "../../utils/utils";
const userType = localStorage.getItem("type");
// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

var columns = [
  // {
  //   id: "checkMark",
  //   label: "Select",
  //   align: "center",
  //   minWidth: 10,
  // },
  {
    id: "id",
    label: "Sr. No.",
    align: "left",
    minWidth: 40,
  },
  {
    id: "customerName",
    label: "Client",
    align: "left",
    minWidth: 40,
  },

  {
    id: "instrumentName",
    label: "Instrument Name",
    align: "left",
    minWidth: 40,
  },
  {
    id: "range",
    label: "Range",
    minWidth: 40,
    align: "left",
  },
  {
    id: "lc",
    label: "LC",
    minWidth: 40,
    align: "left",
  },
  {
    id: "idNo",
    label: "DUC ID",
    minWidth: 40,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "serialNumber",
    label: "Serial Number",
    minWidth: 40,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
  // {
  //   id: "make",
  //   label: "Make",
  //   minWidth: 40,
  //   align: "left",
  // },
  {
    id: "model",
    label: "Model",
    minWidth: 40,
    align: "left",
  },
  {
    id: "startDate",
    label: "Start Date",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "dueDate",
    label: "Due Date",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "reason",
    label: "Reason",
    minWidth: 40,
    align: "left",
    format: (value) => value.toFixed(2),
  },
  {
    id: "Status",
    label: "Status",
    minWidth: 40,
    align: "left",
  },
  // {
  //   id: "action",
  //   label: "Action",
  //   minWidth: 40,
  //   align: "left",
  // },
];

var createData = (
  id,
  customerName,
  workOrderNo,
  instrument,
  identificationNo,
  dateOfCalibration,
  calProcRefNo
) => {
  return {
    id,
    customerName,
    workOrderNo,
    instrument,
    identificationNo,
    dateOfCalibration,
    calProcRefNo,
  };
};

const rows = [
  createData(
    2,
    5,
    1,
    "Universal lab",
    "BFI/2112/BFI/22/1/129",
    "Digital Caliper",
    55,
    "1/20/2022 12:00:00 AM",
    "Thermometer rer"
  ),
];

const qrModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const notNeededColumn = [
  "id",
  "status",
  "clientId",
  "dcNumber",
  "identificationNo",
  "dateOfIssue",
  "srnNo",
  "isProgress",
];

export default function RenewCertificate(props) {
  const printComponentRef = React.useRef();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [certificate, setCertificate] = useState([]);
  const urlPath = useLocation();
  const navigate = useNavigate();
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [settings, fetchSettings] = useSettings({});
  const [qrCodeUrl, setQrCodeUrl] = React.useState(null);
  const [qrModalOpen, setQrModalOpen] = React.useState(false);
  const [enableDisable, setEnableData] = React.useState("");
  const [instrumentName, setInstrumentName] = React.useState("");
  const [idNumber, setIdNumber] = React.useState("");
  const [calDate, setCalDate] = React.useState("");
  const [dueDate, setDueDate] = React.useState("");
  const [certReadings, setCertReadings] = React.useState([]);
  const [finalData, setFinalData] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState(
    sessionStorage.getItem("searchBy") || ""
  );
  const [searchKey, setSearchKey] = React.useState(
    sessionStorage.getItem("searchKey") || ""
  );
  const [totalRows, setTotalRows] = React.useState(0);
  const _userId = localStorage.getItem("id");
  const disciplineIDS = localStorage.getItem("disciplineID");
  const _userType = localStorage.getItem("type");
  const _clientId = localStorage.getItem("clientId");
  const [settingList, setSettingList] = React.useState({});
  const [latestSRFId, setLatestSRFId] = useState(null);
  const [serviceReqNumber, setServiceReqNumber] = React.useState(null);
  const [result, setResult] = React.useState([]);
  const [allUsers, setUsers] = React.useState([]);
  const [usersArray, setUsersArray] = React.useState([]);
  const [engineerName, setEngineerName] = React.useState(null);
  const _companyHierarchy = localStorage.getItem("companyHierarchy");
  const [searched, setSearched] = React.useState(false);

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });
  const { setLoader, rolesInfo } = props;

  // data hooks
  const [srfDetails, updateSrfDetails, fetchSrfDetails] = useSrfDetails({
    customerAnnextureFilePaths: [],
    calType: 2,
  });

  const handleOpen = () => setQrModalOpen(true);
  const handleClose = () => setQrModalOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  var refresh = () => {
    window.location.reload(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getQuery = (count) => {
    // let whereConditions1 = "AND DATE(startDate) <= CURDATE()";
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "") {
      let allColName = {
        Client: "companyName",
        "Instrument Name": "instrumentName",
        "DUCID": "d.DUCID",
        "Due Calibration": "AND d.dueDate < CURRENT_TIMESTAMP ",
        "New Calibration": "AND d.status = 1 AND DATE(d.startDate) = CURDATE()",
        Calibrated:
          "AND d.status = 3 AND DATE(d.dueDate) > CURDATE() And  ds.totalReadings > 0",
        // "Calibration Pending": "AND d.status = 3 AND DATE(d.dueDate) > CURDATE() And (d.totalReadings = 0 OR d.totalReadings IS NULL OR d.totalReadings = '0')",
        Assign: ` ${
          engineerName
            ? `AND d.assignedUser like ${engineerName.split(",")[0]}`
            : ""
        }`,
        Pending: `AND (ds.totalReadings = 0 OR ds.totalReadings IS NULL OR ds.totalReadings = '0')${
          engineerName
            ? ` AND d.assignedUser like ${engineerName.split(",")[0]}`
            : ""
        }`,
        Completed: `AND ds.totalReadings != 0${
          engineerName
            ? ` AND d.assignedUser like ${engineerName.split(",")[0]}`
            : ""
        }`,
      };

      let CalibratEngColName = {
        Client: "companyName",
        "Instrument Name": "instrumentName",
        "DUCID": "d.DUCID",
      };

      let colName =
        userType == "2" && props.rolesInfo?.currentRole?.id == 1
          ? CalibratEngColName[searchBy]
          : allColName[searchBy];

      if (searchBy === "Client" || searchBy === "Instrument Name") {
        whereConditions = `and ${colName} like '%${searchKey}%'`;
      } else {
        whereConditions = `${colName}`;
      }
    }

    if (to != null && from != null) {
      if (whereConditions === "") {
        whereConditions = " and ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` startDate between '${moment(from).format(
        "YYYY-MM-DD"
      )}' and '${moment(to).format("YYYY-MM-DD")}' `;
    }

    // if (userType == "2") {
    //   whereConditions +=
    //     whereConditions.length > 0
    //       ? ` and i.disciplineId IN (${disciplineIDS})`
    //       : `and i.disciplineId IN (${disciplineIDS})`;
    // }

    //Approve condition

    if (
      userType == "2" &&
      props.rolesInfo?.currentRole?.id == 2 &&
      disciplineIDS != "null"
    ) {
      whereConditions +=
        whereConditions.length > 0
          ? ` and i.disciplineId IN (${disciplineIDS}) `
          : `and i.disciplineId IN (${disciplineIDS}) `;
      whereConditions +=
        whereConditions.length > 0
          ? ` and d.assignedUser = ${_userId} and ds.totalReadings != 0`
          : ` and d.assignedUser = ${_userId} and ds.totalReadings != 0`;
    }

    //calibration condition

    if (userType == "2" && props.rolesInfo?.currentRole?.id == 1) {
      whereConditions +=
        whereConditions.length > 0
          ? ` and d.assignedUser = ${_userId}`
          : `and d.assignedUser = ${_userId}`;
    }

    if (userType == "3") {
      let dateCondition = (!from && !to) ? ` AND DATE(d.dueDate) <= CURDATE()` : '';
      
      whereConditions +=
        whereConditions.length > 0
          ? ` and d.clientId = ${_clientId} ${dateCondition}`
          : `and d.clientId = ${_clientId} ${dateCondition}`;
    }
    

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

    let data = {
      query: `SELECT d.*, d.ranges, d.lc, i.instrumentName, i.disciplineId, c.companyName, d.assignedUser AS ducAssignedUserID, ds.totalReadings, u.userName AS datasheetAssignedUserName FROM ducMaster d LEFT JOIN instruments i ON d.instrumentId = i.id LEFT JOIN clients c ON d.clientId = c.id LEFT JOIN datasheets ds ON d.srfInstrumentId = ds.id LEFT JOIN users u ON d.assignedUser = u.id WHERE d.instrumentId IS NOT NULL ${whereConditions}  order by d.id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `SELECT COUNT(*) AS no_of_rows FROM ducMaster d LEFT JOIN instruments i ON d.instrumentId = i.id LEFT JOIN clients c ON d.clientId = c.id LEFT JOIN datasheets ds ON d.srfInstrumentId = ds.id LEFT JOIN users u ON d.assignedUser = u.id WHERE d.instrumentId IS NOT NULL  ${whereConditions}  order by d.id DESC`;
    return data;
  };

  // api calls
  function getTotalRows() {
    let data = getQuery(true);

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("datasheet data fetching error: ", err);
      });
  }

  function fetchCertificates() {
    let data = getQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        setCertificate([...res.data]);
      })
      .catch((err) => {
        console.log("srfs data fetching error: ", err);
      });
  }

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  // api calls
  function fetchCertificateReadings() {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `datasheetStaticReadings`)
      .then(async (res) => {
        setCertReadings(res.data);
      })
      .catch((err) => {
        console.log("cert data fetching error: ", err);
      });
  }

  const initializeCertTable = () => {
    for (let i = 0; i < certificate.length; i++) {
      if (certificate[i].cert_dateOfIssue != null) {
        certificate[i]["isProgress"] = 90;
      } else if (
        certReadings.some((data) => data.datasheetId == certificate[i].id)
      ) {
        certificate[i]["isProgress"] = 70;
      } else {
        certificate[i]["isProgress"] = 30;
      }
    }
    setFinalData(certificate);
  };

  const createDataSheet = async (row) => {
    console.log("createDataSheet");

    try {
      const { id, lastModified, ...rest } = row;
      let srfDetails1 = {
        ...row,
        extraColumns: row.extraColumns ? JSON.parse(row.extraColumns) : null,
        calType: 2,
        id: 0,
        serviceReqNumber: serviceReqNumber,
      };

      let readingRows = [
        {
          ...rest,
          extraColumns: rest.extraColumns
            ? JSON.parse(rest.extraColumns)
            : null,
          calType: 2,
          id: 0,
          serviceReqNumber: serviceReqNumber,
        },
      ];

      let inwardInsertID = await handleCreateSubmit(
        srfDetails1,
        readingRows,
        settings,
        setLoader,
        updateSrfDetails,
        latestSRFId
      );

      const startDate = row.startDate ? moment(new Date()) : null;

      let payload = {
        startDate: row.startDate ? startDate.format("YYYY-MM-DD") : null,
        dueDate:
          row.startDate && row.calFrequency
            ? startDate
                .add(row?.calFrequency.split(" ")?.[0], "M")
                .format("YYYY-MM-DD")
            : "",
        status: 3,
        srfInstrumentId: (inwardInsertID && inwardInsertID[0]) || null,
      };

      await axiosWithToken
        .patch(BASE_URL + `ducMaster/${row?.id}`, payload)
        .then((res) => {})
        .catch((err) => {
          console.log("ducMaster Patch error: ", err);
        });
    } catch (error) {
      // Handle the error appropriately
      console.error("An error occurred:", error);
    }
  };

  // const search = () => {
  //   fetchCertificates();
  // };

  useEffect(() => {
    fetchCertificateReadings();
    fetchSettings([
      "srfInstrumentsAdditionalColumns",
      "DUCMasterStatus",
      "Certificate Number",
      "serviceReqNumber",
      "srfInstrumentsAdditionalColumns",
      "ULR number",
      "ULR ID",
      "Customer Annexure",
      "CSRFS",
      "companyHierarchy",
      "serviceReqNumberIAS",
    ]);
  }, []);

  const getNewSRFId = () => {
    let query = {
      query: `select count(1) as count from srfs where calType = 2`,
    };
    return axiosWithToken
      .post(BASE_URL + `dynamic`, query, {
        headers: { requestType: "fetchData" },
      })
      .then((res) => {
        if (res.data.length < 1) setLatestSRFId(0);
        else setLatestSRFId(res.data[0].count);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error("Something went wrong to get SRF ID");
        }
      });
  };

  const updateEnableDisable = (value, id) => {
    const newColumnName = [...enableDisable];
    newColumnName[id] = id + "," + value;
    setEnableData(newColumnName);
  };

  const processEnableDisable = (enableDisable) => {
    if (enableDisable.length > 0) {
      const newResult = enableDisable
        .map(function (item) {
          if (item) {
            let [id, closed] = item.split(",");
            return closed === "true" ? id : null;
          }
        })
        .filter(function (item) {
          return item !== undefined && item !== null;
        });

      return newResult;
    }

    return [];
  };

  const getUserList = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + "users")
      .then((res) => {
        setUsersArray(res.data);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error("Something went wrong !");
        }
      });
  };

  const initializeuserData = () => {
    var pushCArray = [];
    for (let i = 0; i < usersArray.length; i++) {
      if (usersArray[i].type == 2) {
        pushCArray.push({
          label: usersArray[i].id + ", " + usersArray[i].userName,
        });
      }
    }
    setUsers(pushCArray);
  };

  const saveUserAndDsID = (userId, dsId) => {
    let url = BASE_URL;

    var data = {};
    var dsIdArr = dsId.split(",");
    if (userId) {
      dsIdArr.forEach((element) => {
        let data = {
          query: `UPDATE ducMaster SET assignedUser = ${userId} WHERE id=${element};`,
        };
        axiosWithToken
          .post(BASE_URL + `dynamic`, data)
          .then((res) => {
            toast("Datasheet Assigned.");
            refresh();
          })
          .catch((err) => {
            if (err.message !== "request_aborted") {
              toast.error("Something went wrong !");
            }
          });
      });
    }
  };

  const storedSearchBy = sessionStorage.getItem("searched");
  useEffect(() => {
    if (storedSearchBy) {
      setSearched(true);
    }
  }, [storedSearchBy]);

  const search = () => {
    // sessionStorage.setItem("searchBy", searchBy);
    // sessionStorage.setItem("searchKey", searchKey);
    // sessionStorage.setItem("searched", "true");
    // sessionStorage.setItem("currentPath", urlPath.pathname);
    setSearched(true);
    fetchCertificates();
    getTotalRows();
  };
  const clearSearch = () => {
    // sessionStorage.removeItem("searchBy");
    // sessionStorage.removeItem("searchKey");
    // sessionStorage.removeItem("searched", searched);
    // sessionStorage.removeItem("currentPath");
    setSearchBy("");
    setSearchKey("");
    setTo(null);
    setFrom(null);
    setSearched(false);
    // navigate("/scheduler");
  };

  useEffect(() => {
    setResult(processEnableDisable(enableDisable));
  }, [enableDisable]);

  useEffect(() => {
    initializeuserData();
  }, [usersArray]);

  useEffect(() => {
    let serviceReqNumber = settings?.serviceReqNumberIAS?.value;

    if (serviceReqNumber && latestSRFId != null) {
      let generateSRFNumber = generateNumber(
        serviceReqNumber,
        `${SRF_ID_BASE + latestSRFId}`,
        {
          branch: srfDetails?.branch,
          entryDate: srfDetails?.entryDate,
        }
      );

      setServiceReqNumber(generateSRFNumber);
    }
  }, [latestSRFId, settings?.serviceReqNumberIAS]);

  useEffect(() => {
    getNewSRFId();
    getUserList();
  }, []);

  useEffect(() => {
    getTotalRows();
    fetchCertificates();
  }, [rowsPerPage, page, props.rolesInfo?.currentRole?.id]);

  console.log("searched", searched);

  useEffect(() => {
    if (searched == false) {
      fetchCertificates();
      getTotalRows();
    }
    if (searched) {
      setPage(0);
    }
  }, [searched]);

  useEffect(() => {
    initializeCertTable();
  }, [certificate]);

  const renderQrModal = () => {
    return (
      <Modal
        open={qrModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="md"
      >
        <Box sx={qrModalStyle} ref={printComponentRef}>
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            <img src={Global} width="50" alt="IMG" />
            <b>GLOBAL CALIBRATION</b>
          </Typography>
          <hr />

          <div style={{ fontWeight: "bold" }}>
            <span>Inst Name : {instrumentName}</span>
            <br />
            <span>ID No : {idNumber}</span>
            <br />
            <span>Cal. Date : {moment(calDate).format("DD-MM-YYYY")}</span>
            <br />
            <span>
              Due Date : {moment(dueDate).format("DD-MM-YYYY")}
              <div align="right">
                <QRCode value={qrCodeUrl} size={80} align="center" />
              </div>
            </span>
          </div>

          <div align="center" className="textfield">
            {/* <QRCode value={qrCodeUrl} size={80} align="center" /> */}
            <hr />
            <Button size="small" align="center" onClick={handlePrint}>
              Print
            </Button>
          </div>
        </Box>
      </Modal>
    );
  };

  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <br />
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ padding: "5px 5px" }}
        >
          {result && result.length ? (
            <Grid item xs={6} sm={6} md={2} lg={2}>
              <Autocomplete
                sx={{
                  m: 0,
                  minWidth: 180,
                }}
                size="small"
                id="combo-box-demo"
                options={allUsers}
                renderInput={(params) => (
                  <TextField {...params} label="Job Assign" />
                )}
                onInputChange={(event, newInputValue) => {
                  saveUserAndDsID(
                    parseInt(newInputValue.split(",")[0]),
                    result ? result.toString() : ""
                  );
                }}
              />
            </Grid>
          ) : // </Grid>
          null}
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="From"
                value={from}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => {
                  setFrom(newValue);
                  setSearched(false);
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="To"
                value={to}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => {
                  setTo(newValue);
                  setSearched(false);
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <Autocomplete
              sx={{
                m: 0,
                minWidth: 180,
              }}
              size="small"
              id="combo-box-demo"
              value={searchBy}
              options={
                _userType != 3
                  ? userType == "2" && props.rolesInfo?.currentRole?.id === 1
                    ? [
                        { key: "customerName", label: "Client" },
                        { key: "instrumentName", label: "Instrument Name" },
                      ]
                    : [
                        { key: "customerName", label: "Client" },
                        { key: "instrumentName", label: "Instrument Name" },
                        {
                          key: "AND d.dueDate < CURRENT_TIMESTAMP",
                          label: "Due Calibration",
                        },
                        {
                          key: "AND d.status = 1 AND DATE(d.startDate) = CURDATE()",
                          label: "New Calibration",
                        },
                        {
                          key: "AND d.status = 3 AND DATE(d.dueDate) > CURDATE() And ds.totalReadings > 0",
                          label: "Calibrated",
                        },
                        { key: "", label: "Assign" },
                        { key: "", label: "Pending" },
                        { key: "", label: "Completed" },
                      ]
                  : [{ key: "instrumentName", label: "Instrument Name" },
                    { key: "d.DUCID", label: "DUCID" }
                  ]
              }
              renderInput={(params) => (
                <TextField {...params} label="Search By" />
              )}
              onInputChange={(event, newInputValue) => {
                setSearchBy(newInputValue);
                setSearched(false);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            {searchBy === "Completed" ||
            searchBy === "Pending" ||
            searchBy === "Assign" ? (
              <Autocomplete
                sx={{
                  m: 0,
                  minWidth: 180,
                }}
                value={engineerName}
                size="small"
                id="combo-box-demo"
                options={allUsers}
                renderInput={(params) => (
                  <TextField {...params} label="Select Engineer" />
                )}
                onChange={(event, newInputValue) => {
                  setEngineerName(newInputValue?.label);
                  setSearched(false);
                }}
              />
            ) : (
              <TextField
                id="outlined-basic"
                label={"enter " + searchBy}
                value={searchKey}
                size="small"
                variant="outlined"
                inputProps={{
                  autoComplete: "off",
                }}
                onChange={(e) => {
                  setSearchKey(e.target.value);
                  setSearched(false);
                }}
              />
            )}
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={1}>
            <Button
              variant="contained"
              size="small"
              style={{ height: "40px" }}
              onClick={() => {
                searched ? clearSearch() : search();
              }}
            >
              {searched ? <RestartAltIcon /> : <SearchIcon />}
            </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <ExcelDownload
              finalData={finalData}
              notNeededColumn={notNeededColumn}
            />
          </Grid>
        </Grid>
        <br />
        <Table
          stickyHeader
          aria-label="sticky table"
          size="small"
          id="datatable-keytable"
        >
          <TableHead>
            <TableRow>
              {_userType != 3 && (
                <TableCell>
                  <b>Select</b>
                </TableCell>
              )}
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              ))}
              {_userType != 3 && (
                <TableCell>
                  <b>Action</b>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {finalData?.length > 0 ? (
              finalData.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {_userType != 3 && (
                      <TableCell>
                        <Grid item xs={2}>
                          <FormControlLabel
                            classes={{
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                            sx={{
                              gap: 2,
                            }}
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  updateEnableDisable(e.target.checked, row.id);
                                }}
                                disabled={
                                  (row.status === 3 &&
                                    new Date(row.dueDate) >= new Date() &&
                                    row.totalReadings > 0) ||
                                  (userType === "2" &&
                                    props.rolesInfo?.currentRole?.id === 1)
                                }
                              />
                            }
                          />
                        </Grid>
                      </TableCell>
                    )}
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <div>{index + 1}</div>
                      </div>
                    </TableCell>
                    <TableCell>{row.companyName}</TableCell>
                    {/* <TableCell>{row.certificateNumber}</TableCell>
                    <TableCell>{row.ULRNo}</TableCell> */}
                    <TableCell
                      style={{
                        color:
                          row.status === 3 &&
                          new Date(row.dueDate) >= new Date() &&
                          row.totalReadings > 0
                            ? "green"
                            : (row.status === 1 &&
                                new Date(row.startDate).toDateString() ===
                                  new Date().toDateString()) ||
                              new Date(row.dueDate) < new Date()
                            ? "red"
                            : "inherit", // Default color if no condition matches
                      }}
                    >
                      {row.instrumentName}
                    </TableCell>
                    <TableCell>
                      {row.ranges
                        ?.replaceAll("||", ", ")
                        ?.replaceAll("|", " to ")
                        ?.replaceAll("#", "")}
                    </TableCell>
                    <TableCell>
                      {row.lc
                        ?.replaceAll("||", ", ")
                        ?.replaceAll("|", " to ")
                        ?.replaceAll("#", "")}
                    </TableCell>
                    <TableCell>{row.DUCID}</TableCell>
                    <TableCell>{row.serialNumber}</TableCell>

                    <TableCell>{row.model}</TableCell>
                    <TableCell>
                      {moment(row.startDate).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell>
                      {moment(row.dueDate).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell>{row.reason}</TableCell>
                    <TableCell>
                      {row.status === 1 &&
                        new Date(row.startDate).toDateString() ===
                          new Date().toDateString() &&
                        "New Calibration"}

                      {row.status === 3 &&
                        new Date(row.dueDate) < new Date() &&
                        "Due Calibration"}

                      {row.status === 3 &&
                        new Date(row.dueDate) >= new Date() &&
                        row.totalReadings > 0 &&
                        "Calibrated"}

                      {row.status === 3 &&
                        new Date(row.dueDate) >= new Date() &&
                        (row.totalReadings == 0 ||
                          row.totalReadings === null ||
                          row.totalReadings === "0") &&
                        "Pending"}
                    </TableCell>

                    {_userType != 3 && (
                      <TableCell>
                        <div>
                          {(row.status === 1 &&
                            new Date(row.startDate).toDateString() ===
                              new Date().toDateString()) ||
                          new Date(row.dueDate) < new Date() ? (
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => createDataSheet(row)}
                            >
                              Initiate Calibration
                            </Button>
                          ) : null}

                          {row.status === 3 &&
                          new Date(row.dueDate) > new Date() ? (
                            <div style={{ display: "flex" }}>
                              <Tooltip
                                title="View Datasheet"
                                placement="top-start"
                              >
                                <Button
                                  component={Link}
                                  to={`/datasheet/edit/${row.srfInstrumentId}/1`}
                                  style={{ marginRight: "5px" }}
                                >
                                  <NewspaperIcon />
                                </Button>
                              </Tooltip>

                              <Tooltip
                                title="View Certificate"
                                placement="top-start"
                              >
                                <Button
                                  component={Link}
                                  to={`/certificate/viewCertificate/${row.srfInstrumentId}`}
                                  style={{ marginRight: "5px" }}
                                >
                                  <DocumentScannerIcon />
                                </Button>
                              </Tooltip>
                            </div>
                          ) : null}
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length + 1} align="center">
                  <h6>
                    <b>Data Not Found</b>
                  </h6>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component="div"
          colSpan={3}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          ActionsComponent={TablePaginationActions}
        />
        <ToastContainer />
      </TableContainer>
      {renderQrModal()}
    </div>
  );
}