import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../global";
import { toast } from "react-toastify";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  TablePagination,
  Button,
  TextField,
  Autocomplete,
  Grid,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { useTheme } from "@mui/material/styles";
import axiosWithToken from "../../utils/components/axiosTokenConfig";
import ErrorModal from "../../utils/components/errorModal";
import ExcelDownload from "../../utils/components/excelDownload";
import ListWrap from "../commonComponent/listWrap";
import { getFormattedDates } from "../../utils/utils";

const notNeededColumn = ["id", "userId", "addressId","status","checkinPhotoPath","checkoutPhoto"];

const AttendanceList = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [AttendancData, setAttendancData] = useState([]);
  const [searchBy, setSearchBy] = React.useState("User Name");
  const [searchKey, setSearchKey] = React.useState("");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [totalRows, setTotalRows] = React.useState(0);
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [userType, setUserType] = React.useState(localStorage.getItem("type"));
  const [userId, setUserId] = React.useState(localStorage.getItem("id"));
  const [branch, setBranch] = React.useState(localStorage.getItem("branch"));
  const [searched, setSearched] = React.useState(false);

  const openErrorModal = (errorMessage, stackTrace, fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const FetchQuery = (count) => {
    let whereConditions = "";
    let pagination_settings = "";

    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "User Name": "u.userName",
        Address: "adr.address",
        "Company Name": "u.companyName",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    if (to != null && from != null) {
      const { fromDate, toDate } = getFormattedDates(from, to);
      if (whereConditions === "") {
        whereConditions = " where ";
      } else {
        whereConditions += " and ";
      }

      whereConditions += ` a.checkinDatetime between '${fromDate}' and '${toDate}' `;
    }

    if (rowsPerPage != -1) {
      pagination_settings = `limit ${rowsPerPage} offset ${
        rowsPerPage * Number(page)
      }`;
    }

     if(userType == 4 && branch.length>0){
      whereConditions +=
      whereConditions.length > 0
        ?  `and u.branch in (${branch})`
        : `where u.branch in (${branch})`;
     }

    if (userType == "2") {
      whereConditions +=
        whereConditions.length > 0
          ? ` and u.id=${userId}`
          : ` where u.id=${userId}`;
    }

    let data = {
      query: `SELECT a.*, u.userName,u.companyName, adr.address FROM attendance a JOIN users u ON a.userId = u.id LEFT JOIN addresses adr ON a.addressId = adr.id ${whereConditions}  ORDER BY a.id DESC ${pagination_settings}`,
    };

    if (count)
      data.query = `select count(a.id) as no_of_rows FROM attendance a JOIN users u ON a.userId = u.id LEFT JOIN addresses adr ON a.addressId = adr.id ${whereConditions} ORDER BY a.id DESC`;
    return data;
  };

  const fetchData = () => {
    const sqlQuery = FetchQuery();

    axiosWithToken
      .post(BASE_URL + `dynamic`, sqlQuery)
      .then((res) => {
        setAttendancData(res?.data);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "fetchData", "AttendanceList")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  };

  const getTotalRows = () => {
    const sqlQuery = FetchQuery("count");
    axiosWithToken
      .post(BASE_URL + `dynamic`, sqlQuery)
      .then(async (res) => {
        setTotalRows(res.data[0]?.no_of_rows);
      })
      .catch((err) => {
        console.log("Row data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "getTotalRows", "AttendanceList")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  };

  const search = () => {
    setSearched(true);
    fetchData();
    getTotalRows();
  };
  const clearSearch = () => {
    setSearchBy("");
    setSearchKey("");
    setFrom(null);
    setTo(null);
    setSearched(false); 
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    fetchData();
    getTotalRows();
  }, [rowsPerPage, page]);

  useEffect(() => {
    if (searched==false) {
      fetchData();
      getTotalRows();
    }
    if (searched) {
      setPage(0);
    }
  }, [searched]);

  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  return (
    <ListWrap>
        <Grid
        container
        spacing={2}
        alignItems="center"
        style={{ padding: "5px 0px" }}
      >
         <Grid item xs={6} sm={3} md={1} lg={1.5}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              classNames="mr-2"
            >
              <DatePicker
               className="Listinputcss"
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="From"
                value={from ? new Date(from) : ""}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => {
                  setFrom(newValue);
                  setSearched(false);
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={3} md={1} lg={1.5}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
               className="Listinputcss"
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                label="To"
                value={to ? new Date(to) : ""}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(newValue) => {
                  setTo(newValue);
                  setSearched(false);
                }}
                renderInput={(params) => <TextField {...params} size="small" />}
                style={{ marginRight: "20px" }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
      <Autocomplete
        sx={{
          m: 0,
          width: "100%",
        }}
        className="Listinputcss"
        size="small"
        value={searchBy}
        options={[
          { key: "u.userName", label: "User Name" },
          { key: "adr.address", label: "Address" },
          { key: "u.companyName", label: "Company Name" },
        ]}
        renderInput={(params) => (
          <TextField {...params} label="Search By" />
        )}
        onInputChange={(event, newInputValue) => {
          setSearchBy(newInputValue);
          setSearched(false);
        }}
      />
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
      <TextField
        label={"enter " + searchBy}
        size="small"
        className="Listinputcss"
        variant="outlined"
        value={searchKey}
        inputProps={{
          autoComplete: 'off',
        }}
        onChange={(e) => {
          setSearchKey(e.target.value);
          setSearched(false);
        }}
        />
          </Grid>
          <Grid item xs={6} sm={6} md={1} lg={1}>
          <Button
            variant="contained"
            size="small"
            color={searched ? "error" : "primary"}
            onClick={() => {
              searched ? clearSearch() : search();
            }}
          >
            {searched ? <RestartAltIcon /> : <SearchIcon />}
          </Button>
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
      <ExcelDownload
      finalData={AttendancData}
      notNeededColumn={notNeededColumn}
    />
          </Grid>
      </Grid>
      <TableContainer>
        <Table
          stickyHeader
          aria-label="sticky table"
          size="small"
          id="quotationlist_table"
          width="100%"
          sx={{ borderCollapse: "collapse" }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Sr.No</TableCell>
              <TableCell>User Name</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell> CheckIn Image</TableCell>
              <TableCell>CheckIn Area</TableCell>
              <TableCell>CheckIn Date Time</TableCell>
              <TableCell> CheckOut Image</TableCell>
              <TableCell>CheckOut Area</TableCell>
              <TableCell>CheckOut Date Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {AttendancData?.length > 0 ? (
              AttendancData?.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row?.userName}</TableCell>
                <TableCell>{row?.companyName}</TableCell>
                <TableCell>{row?.address}</TableCell>
                <TableCell>
                  <Avatar src={row?.checkinPhotoPath} />
                </TableCell>
                <TableCell>{`${row?.checkinLat ? `${row?.checkinLat},` : ""} ${
                  row?.checkinLang || ""
                }`}</TableCell>
                <TableCell>
                  {row?.checkinDatetime
                    ? new Date(row?.checkinDatetime).toLocaleString()
                    : ""}
                </TableCell>
                <TableCell>
                  <Avatar src={row?.checkoutPhoto} />
                </TableCell>
                <TableCell>{`${
                  row?.checkoutLat ? `${row?.checkoutLat},` : ""
                } ${row.checkoutLang || ""}`}</TableCell>
                <TableCell>
                  {row?.checkoutDatetime
                    ? new Date(row?.checkoutDatetime).toLocaleString()
                    : ""}
                </TableCell>
              </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  <h6><b>Data Not Found</b></h6>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component="div"
          colSpan={3}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          ActionsComponent={TablePaginationActions}
        />
      </TableContainer>
      {errormodalIsOpen && (
        <ErrorModal
          errormodalIsOpen={errormodalIsOpen}
          closeErrorModal={closeErrorModal}
          errorMessage={errorMessage}
        />
      )}
    </ListWrap>
  );
};

export default AttendanceList;
